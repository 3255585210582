import React, { useState } from 'react';
import { startAuthentication, startRegistration } from '@simplewebauthn/browser';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState('');
    const [invitePhrase, setInvitePhrase] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            console.log('Starting login process...');

            const response = await fetch('https://chatdev.ptr.gg:443/api/auth/generate-authentication-options', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username }),
            });

            if (!response.ok) {
                console.error('Failed to fetch authentication options');
                return;
            }

            const options = await response.json();
            console.log('Authentication options received:', options);

            const authResponse = await startAuthentication(options);
            console.log('Authentication response received:', authResponse);

            // Send authResponse to the server for verification
            const verifyResponse = await fetch('https://chatdev.ptr.gg:443/api/auth/verify-authentication', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ credential: authResponse }),
            });

            if (!verifyResponse.ok) {
                console.error('Failed to verify authentication');
                return;
            }

            const verification = await verifyResponse.json();
            if (verification.success) {
                console.log('Authentication successful!');
                navigate('/chat');
            } else {
                console.error('Authentication failed');
            }
        } catch (error) {
            console.error('An error occurred during login:', error);
        }
    };

    const handleRegister = async () => {
        try {
            console.log('Starting registration process...');

            const response = await fetch('https://chatdev.ptr.gg:443/api/auth/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, invitePhrase }),
            });

            if (!response.ok) {
                console.error('Failed to fetch registration options');
                return;
            }

            const options = await response.json();
            console.log('Registration options received:', options);

            // Ensure that 'name' and 'displayName' are correctly populated
            if (!options.user.name || !options.user.displayName) {
                console.error('Missing required "name" or "displayName" fields in options');
                return;
            }

            const regResponse = await startRegistration(options);
            console.log('Registration response received:', regResponse);

            // Send regResponse to the server for verification
            const verifyResponse = await fetch('https://chatdev.ptr.gg/api/auth/verify-registration', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ credential: regResponse, username }),
            });

            if (!verifyResponse.ok) {
                console.error('Failed to verify registration');
                return;
            }

            const verification = await verifyResponse.json();
            if (verification.success) {
                console.log('Registration successful!');
                // Handle post-registration success actions
            } else {
                console.error('Registration failed');
            }
        } catch (error) {
            console.error('An error occurred during registration:', error);
        }
    };

    return (
        <div>
            <h1>Login to Secure Communication Platform</h1>
            <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your username"
            />
            <input
                type="text"
                value={invitePhrase}
                onChange={(e) => setInvitePhrase(e.target.value)}
                placeholder="Enter your invite code"
            />
            <button onClick={handleLogin}>Login with USB Key</button>
            <button onClick={handleRegister}>Register with USB Key</button>
        </div>
    );
}

export default Login;