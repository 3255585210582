import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Chat from './components/Chat';
import Contacts from './components/Contacts';

function App() {
    console.log('App component rendered')

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="*" element={<Login />} />
            </Routes>
        </Router>
    );
}


export default App;