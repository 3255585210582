import React, { useRef, useEffect } from 'react';
import io from 'socket.io-client';
import CryptoJS from 'crypto-js';

// Replace 'http://yourserver.com:3000' with the actual URL of your server
const socket = io('https://chatdev.ptr.gg:443');

// Function to encrypt messages
const encryptMessage = (message, secret) => {
    return CryptoJS.AES.encrypt(message, secret).toString();
};

// Function to decrypt messages
const decryptMessage = (encryptedMessage, secret) => {
    const bytes = CryptoJS.AES.decrypt(encryptedMessage, secret);
    return bytes.toString(CryptoJS.enc.Utf8);
};

function Chat() {
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const peerConnection = new RTCPeerConnection();

    useEffect(() => {
        // Get user media (video and audio)
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
            localVideoRef.current.srcObject = stream;
            stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
        });

        // Handle incoming media stream
        peerConnection.ontrack = (event) => {
            remoteVideoRef.current.srcObject = event.streams[0];
        };

        // Handle offer from other peer
        socket.on('offer', async (data) => {
            await peerConnection.setRemoteDescription(new RTCSessionDescription(data));
            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);
            socket.emit('answer', answer);
        });

        // Handle answer from other peer
        socket.on('answer', (data) => {
            peerConnection.setRemoteDescription(new RTCSessionDescription(data));
        });

        // Handle ICE candidate from other peer
        socket.on('ice-candidate', (data) => {
            peerConnection.addIceCandidate(new RTCIceCandidate(data));
        });

        // Send ICE candidate to other peer
        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                socket.emit('ice-candidate', event.candidate);
            }
        };
    }, []);

    // Function to create an offer
    const createOffer = async () => {
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        socket.emit('offer', offer);
    };

    // Function to handle sending messages
    const handleSendMessage = (message) => {
        // Replace 'SECRET_KEY' with your actual secret key
        const encryptedMessage = encryptMessage(message, 'test123');
        socket.emit('message', encryptedMessage);
    };

    // Handle incoming encrypted message
    socket.on('message', (encryptedMessage) => {
        // Replace 'SECRET_KEY' with your actual secret key
        const message = decryptMessage(encryptedMessage, 'test123');
        // Display the decrypted message (implementation not shown)
        console.log('Decrypted message:', message);
    });

    return (
        <div>
            <video ref={localVideoRef} autoPlay muted />
            <video ref={remoteVideoRef} autoPlay />
            <button onClick={createOffer}>Call</button>
            {/* Input and button for sending messages */}
            <input type="text" placeholder="Type a message" id="messageInput" />
            <button onClick={() => handleSendMessage(document.getElementById('messageInput').value)}>Send</button>
        </div>
    );
}

export default Chat;